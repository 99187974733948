import { useStoreMenuStatuses } from '~/stores/client/menuStatuses';
import { useStoreCategoryPage } from '~/stores/strapi/categoryPage';
import { useStoreInfoPages } from '~/stores/strapi/infoPages';
import { EPageType } from '~/types/pageType';
import type { RouterConfig } from '@nuxt/schema';

const headerHeight = 72;
const headerOffset = headerHeight + 12;

export default <RouterConfig>{
  scrollBehavior(to, from, savedPosition) {
    if (to.params?.ignoreScroll) {
      return {};
    }
    // Прямой возврат в начало страницы
    if (savedPosition) {
      if (to.fullPath === from.fullPath) {
        return {};
      }
      const nuxtApp = useNuxtApp();
      return new Promise((resolve) => {
        nuxtApp.hooks.hookOnce('page:finish', async () => {
          await nextTick();
          const pagesStore = useStoreInfoPages();
          if (
            pagesStore.pageType === EPageType.category ||
            pagesStore.pageType === EPageType.provider ||
            pagesStore.pageType === EPageType.slotTheme
          ) {
            resolve(savedPosition);
          } else {
            resolve({ left: 0, top: 0 });
          }
        });
      });
    }

    // Проверка хэша или параметра маршрута
    if (to.hash) {
      if (to.path === from.path) {
        return { el: to.hash, top: 72 };
      }
      const nuxtApp = useNuxtApp();
      return new Promise((resolve) => {
        nuxtApp.hooks.hookOnce('page:finish', async () => {
          await nextTick();
          resolve({ el: to.hash, top: 72 });
        });
      });
    }
    const nuxtApp = useNuxtApp();
    return new Promise((resolve) => {
      const storeMenuStatuses = useStoreMenuStatuses();
      if (to.path === from.path && storeMenuStatuses.scrollToElement) {
        setTimeout(() => {
          resolve({ el: storeMenuStatuses.scrollToElement!, top: headerOffset });
          storeMenuStatuses.setScrollToElement(null);
        });

        return;
      }
      nuxtApp.hooks.hookOnce('page:finish', async () => {
        await nextTick();
        if (storeMenuStatuses.scrollToElement) {
          resolve({ el: storeMenuStatuses.scrollToElement, top: headerOffset });
          storeMenuStatuses.setScrollToElement(null);
          return;
        }
        const pagesStore = useStoreInfoPages();
        const storeCategoryPage = useStoreCategoryPage();

        // Handle /games/ and info page types
        if (from.path.includes('/games/') || pagesStore.pageType === EPageType.info) {
          resolve({ left: 0, top: 0 });
          return;
        }

        const isAllowScroll =
          pagesStore.pageType === EPageType.category ||
          pagesStore.pageType === EPageType.provider ||
          pagesStore.pageType === EPageType.slotTheme;

        if (!isAllowScroll) {
          resolve({ left: 0, top: 0 });
          return;
        }

        const el = document.querySelector('#category');
        const isScrollHigherCatergories = el?.getBoundingClientRect()?.top + 72 > 0;

        if (isScrollHigherCatergories) {
          resolve({ left: 0, top: storeCategoryPage.categoryScrollPosition || 0 });
        } else {
          resolve({ el: '#category', top: headerOffset });
        }
      });
    });
    // return { left: 0, top: 0 }; // Возвращаем позицию в начало страницы
  },
  // scrollBehavior(to, _from, savedPosition) {
  //   console.log(to, _from, savedPosition);
  //   if (to.params?.ignoreScroll) {
  //     return {};
  //   }
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     let position = {};
  //     if (to.matched.length < 2) {
  //       position = { x: 0, y: 0 };
  //     }
  //     if (to.hash) {
  //       position = { selector: to.hash };
  //     }
  //     if (to.params.scrollToSelector) {
  //       position = {
  //         selector: to.params.scrollToSelector,
  //         behavior: 'smooth',
  //         offset: { x: 0, y: 110 },
  //       };
  //     }
  //     return position;
  //   }
  // },
};
